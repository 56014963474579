<template>
  <v-card>
    <v-toolbar color="color1 color1Text--text" :dark="color1IsDark">
      <v-toolbar-title>Bid Manager{{ program && program.name ? ` - ${program.name}` : '' }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        :slot="$vuetify.breakpoint.smAndDown ? 'extension' : null"
        color="color3"
        append-icon="fas fa-search"
        label="Search"
        hide-details
        v-model="search"
        solo-inverted
        clearable
      ></v-text-field>
      <bid-program-settings :program="program"></bid-program-settings>
    </v-toolbar>
    <v-card-text>
      <v-row dense>
        <v-col cols="6" class="title">
          Bids Issued
        </v-col>
        <v-col cols="6" class="title text-right">
          <v-btn color="color3 color3Text--text" class="mr-1" fab small @click.stop="showDupes = !showDupes">
            <v-icon>fas fa-clone</v-icon>
          </v-btn>
          <v-btn color="color3 color3Text--text" class="mr-1" fab small @click.stop="onAddClick">
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-btn color="color3 color3Text--text" class="mr-1" fab small @click.stop="load" :loading="loading">
            <v-icon>fas fa-sync-alt</v-icon>
          </v-btn>
          <v-btn color="color3 color3Text--text" class="mr-1" fab small @click.stop="sendAll = true" :loading="loading">
            <v-icon>fas fa-mail-bulk</v-icon>
          </v-btn>
          <vbl-confirm-dialog
            :ask="sendAll"
            :loading="loading"
            :question="'Send all unsent bids?'"
            @deny="sendAll = false"
            @confirm="doSendAll"
          ></vbl-confirm-dialog>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="rows"
      :options.sync="pagination"
      :loading="loading"
      :search="search"
      color="color3"
    >
      <template v-slot:progress>
        <v-progress-linear color="color3" indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`item.bidId`]="{item}">
        <v-icon v-if="item.trickle">fas fa-faucet-drip</v-icon>
        <div v-else>{{item.bidId}}</div>
      </template>

      <template v-slot:[`item.dtEarned`]="{item}">
        {{item.dtEarned.format('L')}}
      </template>

      <template v-slot:[`item.playerNames`]="{item}">
        <template v-if="tournament.sanctioningBody === 'AAU'">
          <div v-for="player in item.players" :key="player.id" :class="{'error--text': !player.aauNumber}">
            {{player.fullName}} {{player.aauNumber}}
          </div>
        </template>
        <template v-else-if="tournament.sanctioningBody === 'p1440'">
          <div v-for="player in item.players" :key="player.id" :class="{'error--text': !player.p1440Number}">
            {{player.fullName}} {{player.p1440Number}}
          </div>
        </template>
        <template v-else>
          {{item.playerNames | formatArray}}
        </template>
      </template>

      <template v-slot:[`item.dupe`]="{item}">
        <v-icon color="error" small v-if="item.dupe">fas fa-clone</v-icon>
      </template>

      <template v-slot:[`item.used`]="{item}">
        <v-checkbox readonly v-model="item.used" hide-details color="success"></v-checkbox>
      </template>

      <template v-slot:[`item.emailCount`]="{item}">
        <v-checkbox readonly v-model="item.emailCount" hide-details color="success"></v-checkbox>
      </template>

      <template v-slot:[`item.actions`]="{item}">
        <v-btn color="color3Text color3--text" class="ma-0" fab small @click="selectBid(item)">
          <v-icon>fas fa-eye</v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
      transition="dialog-transition"
      :persistent="persistDialog"
    >
      <bid-details
        ref="details"
        :bid="selectedBid"
        :active="dialog"
        @cancel-click="dialog = false"
        @editing-change="onEditingChange"
        :options="options"
        @save-complete="onSaveComplete"
        @delete-complete="onDeleteComplete"
        :teamIds="teamIds"
        :bids="bids"
        :program="program"
      ></bid-details>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import BidProgramSettings from '@/components/Tournament/Bids/BidProgramSettings'
import BidDetails from '@/components/Tournament/Bids/BidDetails'
import Bid from '@/classes/Bid'
import { firstBy } from 'thenby'

export default {
  props: ['active', 'program', 'tournamentId'],
  data () {
    return {
      selectedBid: null,
      dialog: false,
      loading: false,
      loadingOptions: false,
      search: null,
      bids: [],
      pagination: {
        itemsPerPage: 25,
        sortBy: ['timestamp']
      },
      headers: [
        { text: 'Bid Id', value: 'bidId' },
        { text: 'Earned On', value: 'dtEarned' },
        { text: 'Earned At', value: 'earnedAt' },
        { text: 'Earned For', value: 'earnedFor' },
        { text: 'Issued To', value: 'playerNames' },
        { text: 'Duplicate', value: 'dupe' },
        { text: 'Used', value: 'used' },
        { text: 'Sent', value: 'emailCount' },
        { text: '', sortable: false, value: 'actions' }
      ],
      persistDialog: false,
      showDupes: false,
      sendAll: false
    }
  },
  computed: {
    ...mapGetters(['user', 'tournament', 'color1IsDark']),
    options () {
      return {
        gender: this.tournament.genderOptions,
        divisions: this.tournament.divisionOptions
      }
    },
    teamIds () {
      return this.bids.map(m => m.teamId)
    },
    myBids () {
      return this.bids && this.bids.map(m => {
        m.dupe = !!this.bids.find(f => f.teamId === m.teamId && f.id !== m.id)
        return m
      })
    },
    dupes () {
      return this.myBids && this.myBids.filter(f => f.dupe).sort(firstBy('teamId'))
    },
    rows () {
      return this.showDupes ? this.dupes : this.myBids
    }
  },
  methods: {
    onDeleteComplete () {
      this.bids = this.bids.filter(f => f.id !== this.selectedBid.id)
      this.dialog = false
    },
    onSaveComplete () {
      var x = this.bids.find(f => f.id === this.selectedBid.id)
      if (!x) this.bids.push(this.selectedBid)
    },
    onUploadComplete (dto) {
      this.bids.push(...dto)
    },
    onAddClick () {
      this.selectBid(new Bid({
        bidProgramId: this.program.id,
        subPolicy: this.program.subPolicy,
        discountAmount: this.program.discountAmount,
        discountPercentage: this.program.discountPercentage,
        tournamentId: this.tournamentId
      }))
    },
    load () {
      this.loading = true
      const dto = {
        programId: this.program.id,
        tournamentId: this.tournamentId || 0,
        divisionId: this.divisionId || 0
      }
      this.$VBL.get.bids(dto)
        .then(r => {
          if (r.data) {
            this.bids = r.data.map(m => new Bid(m))
          }
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    selectBid (bid) {
      this.selectedBid = bid.bidId ? this.bids.find(f => f.bidId === bid.bidId) : bid
      this.dialog = true
    },
    onEditingChange (val) {
      this.persistDialog = val
    },
    doSendAll () {
      this.loading = true
      this.$VBL.http.get(`Notification/SendAllBids/${this.tournamentId}`)
        .then(r => {
          this.sendAll = false
          this.load()
        })
        .catch(e => {
          console.log(e.response)
          this.loading = false
        })
    }
  },
  watch: {
    active: function (val) {
      if (val) {
        this.load()
      }
    }
  },
  components: {
    BidProgramSettings,
    BidDetails
  },
  mounted () {
    this.load()
  }
}
</script>

<style>

</style>
